<template>
  <a-spin :spinning="false">
    <section class="head">
      <div class="content">
        <div class="my-result" @click="gotodetail">{{ $t('quesition.my_result') }}</div>
        <!-- 我的结果 -->
        <h1>{{ pageName || dataInfo.questionnaireName }}</h1>
        <p>{{ dataInfo.intro }}</p>
      </div>
    </section>
    <section class="questions disabled">
      <div class="content">
        <a-form layout="vertical">
          <template v-for="(item, index) in quesData" :key="index">
            <a-form-item
              v-if="item.questionType == 1"
              :label="index + 1 + '.' + item.title"
              :name="item.questionId"
            >
              <div
                class="tit-media-wrap"
                v-if="item.titimgs && item.titimgs.length"
              >
                <a-image
                  v-if="getResourceType(item.titimgs[0]) == 'image'"
                  :src="item.titimgs[0]"
                />
                <video
                  controlslist="nodownload"
                  v-else-if="getResourceType(item.titimgs[0]) == 'video'"
                  :src="item.titimgs[0]"
                  controls
                />
                <audio
                  controlslist="nodownload"
                  v-else-if="getResourceType(item.titimgs[0]) == 'audio'"
                  :src="item.titimgs[0]"
                  controls
                />
              </div>
              <div
                class="col-1"
                v-for="(opt, optIndex) in item.scoreList"
                :key="optIndex"
              >
                <div class="options">
                  <a-radio class="opt-radio" v-model:checked="opt.checked">{{
                    opt.option
                  }}</a-radio>
                  <div class="opt-media-wrap" v-if="opt.media">
                    <a-image
                      v-if="getResourceType(opt.media) == 'image'"
                      :src="opt.media"
                    />
                    <video
                      controlslist="nodownload"
                      v-else-if="getResourceType(opt.media) == 'video'"
                      :src="opt.media"
                      controls
                    />
                    <audio
                      controlslist="nodownload"
                      v-else-if="getResourceType(opt.media) == 'audio'"
                      :src="opt.media"
                      controls
                    />
                  </div>
                </div>
                <div class="statistics">
                  <a-progress
                    :percent="statisticsResults(item.scoreRange[optIndex])"
                    :strokeColor="getColor(optIndex)"
                    status="normal"
                    :strokeWidth="15"
                  />
                  <span>({{ item.scoreRange[optIndex] }} {{ $t('mine.people') }})</span>
                  <!-- 人 -->
                </div>
              </div>
              <ul class="analysis">
                <li v-for="(a, aI) in item.analysisA" :key="aI">
                  {{ aI + 1 }}、<OpenData
                    type="userName"
                    :openid="a.realName"
                  />：
                  {{ a[`ps${item.options.length}`] }}
                </li>
              </ul>
            </a-form-item>
            <a-form-item
              v-if="item.questionType == 2"
              :label="index + 1 + '.' + item.title"
              :name="item.questionId"
            >
              <div
                class="tit-media-wrap"
                v-if="item.titimgs && item.titimgs.length"
              >
                <a-image
                  v-if="getResourceType(item.titimgs[0]) == 'image'"
                  :src="item.titimgs[0]"
                />
                <video
                  controlslist="nodownload"
                  v-else-if="getResourceType(item.titimgs[0]) == 'video'"
                  :src="item.titimgs[0]"
                  controls
                />
                <audio
                  controlslist="nodownload"
                  v-else-if="getResourceType(item.titimgs[0]) == 'audio'"
                  :src="item.titimgs[0]"
                  controls
                />
              </div>
              <div
                class="col-1"
                v-for="(opt, optIndex) in item.scoreList"
                :key="optIndex"
              >
                <div class="options">
                  <a-checkbox
                    class="opt-checkbox"
                    v-model:checked="opt.checked"
                    >{{ opt.option }}</a-checkbox
                  >
                  <div class="opt-media-wrap" v-if="opt.media">
                    <a-image
                      v-if="getResourceType(opt.media) == 'image'"
                      :src="opt.media"
                    />
                    <video
                      controlslist="nodownload"
                      v-else-if="getResourceType(opt.media) == 'video'"
                      :src="opt.media"
                      controls
                    />
                    <audio
                      controlslist="nodownload"
                      v-else-if="getResourceType(opt.media) == 'audio'"
                      :src="opt.media"
                      controls
                    />
                  </div>
                </div>
                <div class="statistics">
                  <a-progress
                    :percent="statisticsResults(item.scoreRange[optIndex])"
                    :strokeColor="getColor(optIndex)"
                    status="normal"
                    :strokeWidth="15"
                  />
                  <span>({{ item.scoreRange[optIndex] }} {{ $t('mine.people') }})</span>
                </div>
              </div>
              <ul class="analysis">
                <li v-for="(a, aI) in item.analysisA" :key="aI">
                  {{ aI + 1 }}、<OpenData
                    type="userName"
                    :openid="a.realName"
                  />：
                  <!-- 追评 -->
                  {{ a[`ps${item.options.length}`] }}
                </li>
              </ul>
            </a-form-item>
            <a-form-item
              v-if="item.questionType == 9"
              :label="index + 1 + '.' + item.title"
              :name="item.questionId"
            >
              <div
                class="tit-media-wrap"
                v-if="item.titimgs && item.titimgs.length"
              >
                <a-image
                  v-if="getResourceType(item.titimgs[0]) == 'image'"
                  :src="item.titimgs[0]"
                />
                <video
                  controlslist="nodownload"
                  v-else-if="getResourceType(item.titimgs[0]) == 'video'"
                  :src="item.titimgs[0]"
                  controls
                />
                <audio
                  controlslist="nodownload"
                  v-else-if="getResourceType(item.titimgs[0]) == 'audio'"
                  :src="item.titimgs[0]"
                  controls
                />
              </div>
              <div
                class="pic-opt-wrap"
                v-for="(opt, optIndex) in item.scoreList"
                :key="optIndex"
              >
                <div class="pic-radio">
                  <div class="pic">
                    <a-image :src="opt.media" />
                  </div>
                  <a-radio class="opt-radio" v-model:checked="opt.checked">{{
                    opt.option
                  }}</a-radio>
                  <div v-if="item.remarks[optIndex]">
                    <a-popover :title="opt.option" placement="bottom">
                      <template #content>
                        <div style="max-width: 400px">
                          {{ item.remarks[optIndex] }}
                        </div>
                      </template>
                      <div class="opt-dec">【{{ $t('quesition.view_option_desc') }}】</div>
                      <!-- 【查看选项说明】 -->
                    </a-popover>
                  </div>
                </div>
                <div class="statistics">
                  <a-progress
                    :percent="statisticsResults(item.scoreRange[optIndex])"
                    :strokeColor="getColor(optIndex)"
                    status="normal"
                    :strokeWidth="15"
                  />
                  <span>({{ item.scoreRange[optIndex] }} {{ $t('mine.people') }})</span>
                </div>
              </div>
            </a-form-item>
            <a-form-item
              v-if="item.questionType == 10"
              :label="index + 1 + '.' + item.title"
              :name="item.questionId"
            >
              <div
                class="tit-media-wrap"
                v-if="item.titimgs && item.titimgs.length"
              >
                <a-image
                  v-if="getResourceType(item.titimgs[0]) == 'image'"
                  :src="item.titimgs[0]"
                />
                <video
                  controlslist="nodownload"
                  v-else-if="getResourceType(item.titimgs[0]) == 'video'"
                  :src="item.titimgs[0]"
                  controls
                />
                <audio
                  controlslist="nodownload"
                  v-else-if="getResourceType(item.titimgs[0]) == 'audio'"
                  :src="item.titimgs[0]"
                  controls
                />
              </div>
              <div
                class="pic-opt-wrap"
                v-for="(opt, optIndex) in item.scoreList"
                :key="optIndex"
              >
                <div class="pic-radio">
                  <div class="pic">
                    <a-image :src="opt.media" />
                  </div>
                  <a-checkbox
                    class="opt-checkbox"
                    v-model:checked="opt.checked"
                    >{{ opt.option }}</a-checkbox
                  >
                  <div v-if="item.remarks[optIndex]">
                    <a-popover
                      :title="'选项' + (optIndex + 1)"
                      placement="bottom"
                    >
                      <template #content>
                        <div style="max-width: 400px">
                          {{ item.remarks[optIndex] }}
                        </div>
                      </template>
                      <div class="opt-dec">【{{ $t('quesition.view_option_desc') }}】</div>
                      <!-- 【查看选项说明】 -->
                    </a-popover>
                  </div>
                </div>
                <div class="statistics">
                  <a-progress
                    :percent="statisticsResults(item.scoreRange[optIndex])"
                    :strokeColor="getColor(optIndex)"
                    status="normal"
                    :strokeWidth="15"
                  />
                  <span>({{ item.scoreRange[optIndex] }} {{ $t('mine.people') }})</span>
                </div>
              </div>
            </a-form-item>
            <a-form-item
              v-if="item.questionType == 5"
              class="area-input"
              :label="index + 1 + '.' + item.title"
              :name="item.questionId"
            >
              <div
                class="tit-media-wrap"
                v-if="item.titimgs && item.titimgs.length"
              >
                <a-image
                  v-if="getResourceType(item.titimgs[0]) == 'image'"
                  :src="item.titimgs[0]"
                />
                <video
                  controlslist="nodownload"
                  v-else-if="getResourceType(item.titimgs[0]) == 'video'"
                  :src="item.titimgs[0]"
                  controls
                />
                <audio
                  controlslist="nodownload"
                  v-else-if="getResourceType(item.titimgs[0]) == 'audio'"
                  :src="item.titimgs[0]"
                  controls
                />
              </div>
              <p style="text-indent: 12px; color: #999">
                {{ $t('quesition.nth_people_have_answered', [item.analysis.length ? item.analysis.length : 0 ]) }}
                <!-- 已有{0}人回答 -->
              </p>
              <ul class="analysis">
                <li v-for="(a, aI) in item.analysis" :key="aI">
                  {{ aI + 1 }}、<OpenData
                    type="userName"
                    :openid="a.realName"
                  />：{{ a.answer }};
                  <temlate>{{ a[`ps${a.score}`] }}</temlate>
                </li>
              </ul>
            </a-form-item>
            <a-form-item
              v-if="item.questionType == 6"
              :label="index + 1 + '.' + item.title"
              :name="item.questionId"
            >
              <div
                class="tit-media-wrap"
                v-if="item.titimgs && item.titimgs.length"
              >
                <a-image
                  v-if="getResourceType(item.titimgs[0]) == 'image'"
                  :src="item.titimgs[0]"
                />
                <video
                  controlsList="nodownload"
                  v-else-if="getResourceType(item.titimgs[0]) == 'video'"
                  :src="item.titimgs[0]"
                  controls
                />
                <audio
                  controlsList="nodownload"
                  v-else-if="getResourceType(item.titimgs[0]) == 'audio'"
                  :src="item.titimgs[0]"
                  controls
                />
              </div>
              <div class="score-item" v-for="(s, sI) in item.scores" :key="sI">
                <a-rate
                  v-model:value="item.scores[sI]"
                  :count="item.scores.length"
                  :disabled="true"
                >
                  <template #character>
                    <StarFilled
                      :style="{ fontSize: '32px' }"
                      v-if="item.settingObj.starType == 1"
                    />
                    <HeartFilled
                      :style="{ fontSize: '32px' }"
                      v-else-if="item.settingObj.starType == 2"
                    />
                    <SmileFilled
                      :style="{ fontSize: '32px' }"
                      v-else-if="item.settingObj.starType == 3"
                    />
                    <LikeFilled
                      :style="{ fontSize: '32px' }"
                      v-else-if="item.settingObj.starType == 4"
                    />
                  </template>
                </a-rate>
                <span>{{ item.options[sI] }}; {{ item.labels[sI] }}</span>
                <div class="statistics bf">
                  <a-progress
                    :percent="statisticsResults(item.scoreRange[sI])"
                    :strokeColor="getColor(sI)"
                    status="normal"
                    :strokeWidth="15"
                  />
                  <span>({{ item.scoreRange[sI] }} {{ $t('mine.people') }})</span>
                </div>
              </div>
              <ul class="analysis">
                <li v-for="(a, aI) in item.analysis" :key="aI">
                  {{ aI + 1 }}、<OpenData
                    type="userName"
                    :openid="a.realName"
                  />：{{ item.options[a.score - 1] }};
                  <!-- {{ item.labels[a.score - 1] }} -->
                  <!-- 追评 -->
                  <temlate>{{ a[`ps${a.score}`] }}</temlate>
                </li>
              </ul>
            </a-form-item>
            <a-form-item
              v-if="item.questionType == 7"
              :label="index + 1 + '.' + item.title"
              :name="item.questionId"
            >
              <div
                class="tit-media-wrap"
                v-if="item.titimgs && item.titimgs.length"
              >
                <a-image
                  v-if="getResourceType(item.titimgs[0]) == 'image'"
                  :src="item.titimgs[0]"
                />
                <video
                  controlsList="nodownload"
                  v-else-if="getResourceType(item.titimgs[0]) == 'video'"
                  :src="item.titimgs[0]"
                  controls
                />
                <audio
                  controlsList="nodownload"
                  v-else-if="getResourceType(item.titimgs[0]) == 'audio'"
                  :src="item.titimgs[0]"
                  controls
                />
              </div>
              <div class="score-item" v-for="(s, sI) in item.tempScores" :key="sI">
                <a-radio v-if="item.settingObj.starType == 5">{{ s.score }}</a-radio>
                <div class="slider" v-else-if="item.settingObj.starType == 6">
                  <div class="bar">
                    <a-slider
                      v-model:value="s.i"
                      :min="1"
                      :max="item.scoreList.length"
                    />
                  </div>
                  <label class="label">{{ s.score }} {{ item.labels[sI] }}</label>
                </div>
                <a-rate
                  v-else
                  v-model:value="s.i"
                  :count="item.scoreList.length"
                  :disabled="true"
                >
                  <template #character>
                    <StarFilled
                      :style="{ fontSize: '32px' }"
                      v-if="item.settingObj.starType == 1"
                    />
                    <HeartFilled
                      :style="{ fontSize: '32px' }"
                      v-else-if="item.settingObj.starType == 2"
                    />
                    <SmileFilled
                      :style="{ fontSize: '32px' }"
                      v-else-if="item.settingObj.starType == 3"
                    />
                    <LikeFilled
                      :style="{ fontSize: '32px' }"
                      v-else-if="item.settingObj.starType == 4"
                    />
                  </template>
                </a-rate>
                <span v-if="item.settingObj.starType !== 6">{{ item.labels[sI] }}</span>
                <div class="statistics bf">
                  <a-progress
                    :percent="statisticsResults(item.scoreRange[sI])"
                    :strokeColor="getColor(sI)"
                    status="normal"
                    :strokeWidth="15"
                  />
                  <span>({{ item.scoreRange[sI] }} {{ $t('mine.people') }})</span>
                </div>
              </div>
            </a-form-item>
          </template>
        </a-form>
      </div>
    </section>
  </a-spin>
</template>

<script>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { trackQuestionnaireAnalysis } from "@/api/train.js";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    OpenData,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const spinning = ref(false);
    const dataInfo = ref({});
    const quesData = ref([]);
    const taskId = route.query.taskId;
    const detailId = route.query.detailId;
    const questionnaireId = route.query.questionnaireId;
    const pageName = route.query.name;

    const userCount = ref(0);
    const gotodetail = () => {
      router.back();
    };
    const openModal = () => {
      spinning.value = false;
      dataInfo.value = [];
      quesData.value = [];
      const paermes = {
        taskId,
        detailId,
        questionnaireId,
      };
      trackQuestionnaireAnalysis(paermes).then((res) => {
        dataInfo.value = res.data;
        userCount.value = res.data.users;
        quesData.value = res.data.questions || [];
        quesData.value.map((item) => {
          item.settingObj = JSON.parse(item.setting);
          item.scoreList = [];
          item.options = item.options || [];
          item.analysis = item.analysis || [];
          item.options.forEach((opt, i) => {
            let hasAnalysis = false;
            item.analysis.forEach((opt1, i1) => {});
            item.scoreList.push({
              option: opt,
              score: 0,
              hasAnalysis: hasAnalysis,
              remark: item.remarks[i],
              media: item.opimgs[i],
              label: item.labels[i],
              checked: item.analysis[i] > 0 ? true : false,
            });
          });
          // 单选多选没有追评数据过滤
          if (item.questionType == 1 || item.questionType == 2) {
            item.analysisA = item.analysis.filter(
              (a) => a[`ps${item.options.length}`]
            );
          }
          item.scoreRange = new Array(item.scores.length).fill(0);
          item.analysis.forEach((a) => {
            item.scores.map((s, sI) => {
              a[`op${sI + 1}`] > 0 && item.scoreRange[sI]++;
            });
          });
          // 计算评分题和量表题的平均分
          if (item.questionType == 6 || item.questionType == 7) {
            let totalScore = 0;
            let totalUser = 0;
            item.tempScores = [];
            item.tempScores = item.scores.map((s, sIndex) => {
              totalUser += item.analysis[sIndex];
              totalScore += s * item.analysis[sIndex];
              return {
                score: s,
                i: sIndex + 1,
              }
            });
            item.avgScore = parseInt((totalScore / totalUser).toFixed(0));
          }
        });
        spinning.value = false;
      });
    };

    const getResourceType = (url) => {
      if (!url || url == "") {
        return "";
      }
      const imgFormat = ["jpg", "png", "gif"];
      const videoFormat = ["mp4"];
      const audioFormat = ["mp3"];
      let u = url.split(".");
      let suffix = u[u.length - 1].toLowerCase();
      let type = "";
      if (imgFormat.indexOf(suffix) >= 0) {
        type = "image";
      } else if (videoFormat.indexOf(suffix) >= 0) {
        type = "video";
      } else if (audioFormat.indexOf(suffix) >= 0) {
        type = "audio";
      }
      return type;
    };

    const formatter = (value) => {
      return `${value}%`;
    };

    const getColor = (index) => {
      if (index == 0) {
        return "#1890ff";
      } else if (index == 1) {
        return "#ff8500";
      } else if (index == 2) {
        return "#00bb9e";
      } else if (index == 3) {
        return "#0dcfe6";
      } else if (index == 4) {
        return "#f1582b";
      } else if (index == 5) {
        return "#4e23ed";
      } else if (index == 6) {
        return "#e42929";
      } else if (index == 7) {
        return "#f1ad00";
      } else if (index == 8) {
        return "#496df0";
      }
    };

    const statisticsResults = (analysis) => {
      return analysis > 0 ? ((analysis / userCount.value) * 100).toFixed(0) : 0;
    };

    openModal();
    return {
      userCount,
      pageName,
      gotodetail,
      taskId,
      detailId,
      questionnaireId,
      spinning,
      dataInfo,
      quesData,
      getResourceType,
      formatter,
      openModal,
      getColor,
      statisticsResults,
    };
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common.less";
.head {
  padding-top: 40px;
  .content {
    min-height: 115px;
    .mixinWrap(1000px);
    background-color: #fff;
    text-align: center;
    border-radius: 20px;
    border: 1px solid #f5f6f7;
    padding: 46px 0 36px;
    position: relative;
    .my-result {
      position: absolute;
      left: 15px;
      top: 15px;
      width: 118px;
      height: 34px;
      border: 1px solid @color-theme;
      font-size: 16px;
      color: @color-theme;
      text-align: center;
      line-height: 34px;
      cursor: pointer;
    }
    h1 {
      font-size: 28px;
      line-height: 1.4;
      margin-bottom: 10px;
      color: #333;
    }
    p {
      font-size: 16px;
      line-height: 1.4;
      margin-bottom: 0;
      color: #666;
    }
  }
}
.questions {
  padding-bottom: 80px;
  .statistics {
    width: 300px;
    margin: 0 50px;
    display: flex;
    span {
      width: 100px;
      margin-left: 20px;
      margin-bottom: -2px;
    }
  }
  .statistics.bf {
    width: auto;
    margin: 0px;
  }
  .content {
    min-height: 220px;
    .mixinWrap(1000px);
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #f5f6f7;
    padding: 36px;
    .ant-form-item {
      margin-bottom: 16px;
    }
    ::v-deep(.ant-form-item-label) {
      padding-bottom: 16px;
      & > label {
        font-size: 16px;
        align-items: unset;
        &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
          padding-top: 6px;
        }
      }
    }
    .col-1 {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .options {
        width: calc(100% - 400px);
      }
      .comment {
        display: block;
        flex: 1;
      }
    }
    .opt-checkbox {
      pointer-events: none;
    }
    .opt-radio {
      pointer-events: none;
      white-space: normal;
      line-height: 1.8;
      ::v-deep(.ant-radio) {
        vertical-align: text-top;
      }
    }
    .supplement-form-item {
      display: inline-block;
      margin-bottom: 0;
      ::v-deep(.ant-form-item-control-input) {
        min-height: 26px;
      }
      ::v-deep(.ant-input-number:hover .ant-input-number-handler-wrap) {
        opacity: 0;
        display: none;
      }
    }
    .supplement-form-opt {
      width: 880px;
      border-width: 0 !important;
      border-bottom-width: 1px !important;
      box-shadow: none !important;
      border-radius: 0;
    }
    .tit-media-wrap {
      width: 500px;
      padding-bottom: 16px;
      font-size: 0;
      audio,
      video {
        width: 100%;
        font-size: 0;
      }
      audio::-webkit-media-controls-enclosure {
        border-radius: 0;
      }
      ::v-deep(.ant-image) {
        width: 100%;
        max-height: 300px;
        overflow: hidden;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          cursor: pointer;
        }
      }
    }
    .opt-media-wrap {
      width: 300px;
      overflow: hidden;
      audio,
      video {
        width: 100%;
        font-size: 0;
      }
      audio::-webkit-media-controls-enclosure {
        border-radius: 0;
      }
      ::v-deep(.ant-image) {
        width: 100%;
        max-height: 200px;
        overflow: hidden;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          cursor: pointer;
        }
      }
    }
    .pic-opt-wrap {
      .mixinFlex(space-between;center;row);
      flex-wrap: wrap;
      .pic-radio {
        .mixinFlex(flex-start; center; column);
        width: 173px;
        height: 280px;
        margin-right: 15px;
        margin-bottom: 10px;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        &:nth-child(5n) {
          margin-right: 0;
        }
        .pic {
          width: 100%;
          height: 200px;
          background-color: #f1f1f1;
          ::v-deep(.ant-image) {
            width: 100%;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              cursor: pointer;
            }
          }
        }
        .opt-checkbox,
        .opt-radio {
          pointer-events: none;
          width: 100%;
          margin: 0;
          padding: 12px 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
        .opt-dec {
          text-align: center;
          margin-bottom: 12px;
          font-size: 12px;
          color: @color-theme;
          cursor: default;
        }
      }
    }
    ::v-deep(.ant-rate-star:not(:last-child)) {
      margin-right: 16px;
    }
    .star-dec {
      color: #999;
      font-size: 14px;
      padding: 20px 0;
    }
    .star-labs {
      .lab {
        display: inline-block;
        padding: 12px 22px;
        font-size: 12px;
        line-height: 20px;
        background-color: #fff;
        color: #666;
        box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.05);
        border: 1px solid #eeeeee;
        margin: 0 26px 20px 0;
        transition: all 0.3s;
        cursor: pointer;
        &:hover {
          box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
        }
        &.active {
          box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid @color-theme;
          color: @color-theme;
        }
      }
    }
    .measure {
      position: relative;
      .score {
        position: absolute;
        top: -5px;
        right: -32px;
        font-size: 16px;
      }
      .opt-strs {
        .mixinFlex(space-between);
        font-size: 14px;
        color: #333;
        line-height: 20px;
        padding: 10px 0;
      }
      ::v-deep(.ant-rate) {
        .mixinFlex(space-between);
        padding: 5px 0;
      }
      ::v-deep(.ant-radio-group) {
        .mixinFlex(space-between);
        padding: 5px 0;
        span.ant-radio + * {
          padding-right: 0;
        }
      }
    }
    .btns {
      text-align: center;
      .btn {
        background-color: @color-theme;
        color: #fff;
        width: 172px;
        height: 48px;
        border-radius: 24px;
      }
    }
    .slider {
      display: flex;
      align-items: center;
      .bar {
        width: 460px;
      }
      .label {
        margin-left: 10px;
      }
    }
  }
}
</style>
